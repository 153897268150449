<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="6" v-for="(item,index) in course.records" :key="index">
        <el-card class="card" :body-style="{ padding: '0px' }">
          <img :src="item.url" class="image">
          <div class="content">
            <h4>{{ item.title }}</h4>
            <div class="time">收藏于{{ item.gmtCreate }}</div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-pagination
        v-if="hasData"
        background
        :current-page="course.current"
        :page-size="course.size"
        @current-change="getCollect"
        layout="prev, pager, next"
        :total="course.total">
    </el-pagination>
  </div>
</template>

<script>
import {getCollectByUserId} from "@/api/collect";

export default {
  name: "courseWrap",
  data() {
    return {
      course: [],
      hasData: false,
    }
  },
  mounted() {
    // 收集文章
    this.getCollect(1);
  },
  methods: {
    getCollect(number) {
      getCollectByUserId(this.$store.state.user.id, 2, number).then(result => {
        this.hasData = true;
        this.course = result.data.collect;
      }).catch(error => {
        this.$message({
          type: "error",
          message: error.message,
          duration: 2 * 1000
        })
      })
    },
  }
}
</script>

<style scoped>
.card {
  border: 1px solid #f2f2f2;
  padding: 0
}

/* 图片的大小*/
.image {
  width: 100%;
  height: 120px;
  display: block;
}

.content {
  padding: 4px;
}


.time {
  color: #666;
  height: 1.875rem;
  line-height: 1.875rem;
  text-align: left;
}

h4 {
  text-align: left;
  height: 1.875rem;
  line-height: 1.875rem;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #20232c;
  margin-bottom: 4px;
}


</style>
